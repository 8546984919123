<template>
  <b-card title="Editar Moeda">
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Código"
              label-for="codigo"
            >
              <validation-provider
                #default="{ errors }"
                name="Código"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="codigo"
                    v-model="tipoIntervencao.codigo"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalTiposIntervencao"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Descrição"
              label-for="descricao"
            >
              <validation-provider
                #default="{ errors }"
                name="Descrição"
                rules="required"
              >
                <b-form-input
                  id="descricao"
                  v-model="tipoIntervencao.descricao"
                  type="text"
                  placeholder="Descrição"
                  maxlength="50"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Tempo Fixo"
              label-for="h-tempoFixo"
            >
              <validation-provider
                #default="{ errors }"
                name="tempoFixo"
                rules="required"
              >
                <cleave
                  id="tempoFixo"
                  v-model="tipoIntervencao.tempoFixo"
                  class="form-control"
                  :raw="false"
                  :options="options.number"
                  placeholder="0"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Tempo Mínimo"
              label-for="h-tempoDebMin"
            >
              <validation-provider
                #default="{ errors }"
                name="tempoDebMin"
                rules="required"
              >
                <cleave
                  id="tempoDebMin"
                  v-model="tipoIntervencao.tempoDebMin"
                  class="form-control"
                  :raw="false"
                  :options="options.number"
                  placeholder="0"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Implica Deslocações"
              label-for="ImplicaDeslocacoes"
              label-class="mb-1"
            >
              <b-form-checkbox
                id="ImplicaDeslocacoes"
                v-model="tipoIntervencao.ImplicaDeslocacoes"
                value="1"
              />

            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="switchLoc()"
            >
              Gravar
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>

      </validation-observer>
    </b-form>

    <b-modal
      ref="modalTiposIntervencao"
      hide-footer
      size="xl"
    >

      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Pesquisar</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Pesquisar"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'codigo'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.codigo }}</span>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'descricao'">

            {{ props.row.descricao }}

          </span>
          <span v-else-if="props.column.field === 'ImplicaDeslocacoes'">

            <b-form-checkbox
              v-if="props.row.ImplicaDeslocacoes === 1"
              v-model="props.row.ImplicaDeslocacoes"
              :value="1"
              :disabled="true"
            />

          </span>

          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="selectTiposIntervencao(props.row)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Editar</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showMsgBoxTwo(props.row)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Apagar</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mostrar 1 até
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })"
              />
              <span class="text-nowrap "> de {{ props.total }} registos </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

    </b-modal>

  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BInputGroupPrepend,
  BInputGroup, BPagination, BFormSelect, BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BInputGroupPrepend,
    BInputGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BModal,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      required,
      boxTwo: false,
      pageLength: 3,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      tipoIntervencao: {
        id: '',
        codigo: '',
        descricao: '',
        tempoFixo: null,
        tempoDebMin: null,
        ImplicaDeslocacoes: null,
      },

      columns: [
        {
          label: 'Código',
          field: 'codigo',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Descrição',
          field: 'descricao',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Tempo Fixo',
          field: 'tempoFixo',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Tempo Mínimo',
          field: 'tempoDebMin',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Implica Deslicações',
          field: 'ImplicaDeslocacoes',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getTiposIntervencao()
    localize('pt', pt)
  },

  methods: {
    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.saveTiposIntervencao()
        }
      })
    },
    getTiposIntervencao() {
      axios.get('/api/v1/tiposIntervencao')
        .then(res => {
          this.rows = res.data
        })
      this.$forceUpdate()
    },

    saveTiposIntervencao() {
      axios.put(`/api/v1/tiposIntervencao/${this.tipoIntervencao.id}`, this.tipoIntervencao)
        .then(this.showToast('success', 'CheckIcon'))
      this.getTiposIntervencao()
    },

    selectTiposIntervencao(row) {
      this.tipoIntervencao.id = row.id
      this.tipoIntervencao.codigo = row.codigo
      this.tipoIntervencao.descricao = row.descricao
      this.tipoIntervencao.tempoFixo = row.tempoFixo
      this.tipoIntervencao.tempoDebMin = row.tempoDebMin
      this.tipoIntervencao.ImplicaDeslocacoes = row.ImplicaDeslocacoes

      this.hideModalMoeda()
    },

    showModalTiposIntervencao() {
      this.$refs.modalTiposIntervencao.show()
    },
    hideModalMoeda() {
      this.$refs.modalTiposIntervencao.hide()
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    showMsgBoxTwo(row) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Pretende Remover ?', {
          title: 'Confirmação',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
          if (this.boxTwo === true) {
            this.deleted(row)
          }
        })
    },

    deleted(row) {
      axios.delete(`/api/v1/tiposIntervencao/${row.id}`)
        .then(
          this.rows = this.rows.filter(profile => profile.id !== row.id),
        )
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
